import EventStore from './events'
function countUncheckedCheckboxes(domElement) {
    // Select all input elements of type checkbox that are unchecked within the specified DOM element
    const uncheckedCheckboxes = domElement.querySelectorAll('input[type="checkbox"]:not(:checked)');
    
    // Return the count of unchecked checkboxes
    return uncheckedCheckboxes.length;
  }

function handleUpdateActivityList(event, dataset) {
    const evtString = `${event}`
    console.log("handleUpdateActivityList: receive event", evtString)
    if(!evtString.startsWith("activity_done::")){ 
        console.log(`handleUpdateActivityList: pass`);
        return;
    }
    const forms = document.querySelectorAll("form[data-activity-id=\""+dataset.activityId+"\"]");
    if(!forms.length){ 
        console.log(`handleUpdateActivityList: pass`, `no form for ${dataset.activityId}`);
        return;
    }
    const form = forms[0];
    const submitBtns = form.querySelectorAll('.js-activity-done')
    for(let btn of submitBtns){
        console.log(submitBtns, "disabled", btn)
        btn.setAttribute("disabled", true)
    }
}

function handleUpdatedTodo(event, _dataset) {
    const evtString = `${event}`
    if(!evtString.startsWith("activity::")){ 
        console.log(`handleUpdatedTodo: pass`);
        return;
    }
    const activityContainerMatches = document.querySelectorAll(".js-activity-todos");
    if(!activityContainerMatches.length){ 
        console.log(`handleUpdatedTodo: pass`);
        console.log(`missing .js-activity-toodos`);
        return;
    }
    const [_np, activityId] = evtString.split("::");
    let activityContainer;
    for(let container of activityContainerMatches){
        if(container.dataset.activityId === activityId)
            activityContainer = container;
    }

    if(activityId && !activityContainer ) {
        console.log(`handleUpdatedTodo: pass`);
        console.log(`currentActivityId=${currentActivityId},activityId=${activityId} don't match`);
        return;
    }
    // count unchecked checkboxes
    const missingTodos = countUncheckedCheckboxes(activityContainer)
    if(missingTodos === 0) {
        window.location.reload();
    }else {
        console.log(`${missingTodos} todos up to do!`)
    }
}


document.addEventListener("turbo:load", function() {
  EventStore.on(handleUpdatedTodo)
  EventStore.on(handleUpdateActivityList)
    
    const todos = document.querySelectorAll('.js-todo-checkbox');

    if (todos.length === 0) {
      return;
    }
    // Submit form on change.
    todos.forEach(todo => {
        todo.addEventListener("change", (event) => {
            const checkBox = event.target;
            // parent is a <button type="submit">
            const label = checkBox.closest("label")
            const parent = label.closest("form")
            parent.dispatchEvent(new Event('submit', {
                bubbles: true,
                cancelable: true
            }))
        })
    })

})